<template>
  <page-container :title="nsfile_id + '#' + '保险批量创建'">
    <template #tags>
      <span style="font-weight: bolder; font-size: 20px">
        【
        <span style="color: #00DD00">
          <check-circle-outlined style="color: #00DD00" />
          {{ create_number }}
        </span>
        <span style="color: #f5222d">
          <exclamation-circle-outlined style="color: #ff0000; margin-left: 8px" />
          {{ dataList.length - create_number }}
        </span>
        】
      </span>
    </template>
    <div :style="'height: ' + tableHeight.y + 'px;overflow-y: scroll'">
      <template v-for="obj in dataList" :key="obj.index">
        <show-insurance
          :insurance-obj="obj"
          :insurance-create="obj.is_create"
          @ok="is_create_number(obj)"
        />
      </template>
    </div>
  </page-container>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref } from 'vue';
import { useRoute } from 'vue-router';
import { import_invoice, query_adviser_list } from '@/api/cerp-model';
import { useStore } from 'vuex';
import { btn_update_adviser } from '@/views/profile/basic/basic.ts';
import ShowInsurance from '@/components/show-insurance/index.vue';
import { ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons-vue';

export default defineComponent({
  setup() {
    const route = useRoute();
    const store = useStore();
    const nsfile_id = Number(route.params['id']);
    const tableHeight = reactive({ y: window.innerHeight - 210 });
    onMounted(() => {
      window.onresize = function () {
        tableHeight.y = window.innerHeight - 210;
      };
    });
    route.meta.title = Number(route.params['id']) + '# 保险批量创建';
    const current_org = store.getters['user/current_org'];
    const list: any = [
      {
        is_create: false,
        adviser__full_name: '三 吴',
        adviser_id: 1, // 顾问id
        insurance_company_name: '保险公司名',
        policy_no: '123456798', // 保险单号
        start_end_date: ['2020-10-01', '2021-10-01'], // 保险起止日期
        insurance_fee: '1231', // 保费
        month_insurance_fee: '123', // 每月保费
        beneficiary_list: reactive([
          // 受益人列表
          {
            name: '小吴',
            id_number: '123456789123', // 证件号
            gender: 0, // 性别
            date_of_birth: '2020-10-01', // 出生日期
            id_type_id: '身份证', // 证件类别
            id_expiry_date: '2020-10-01', // 证件有效期
          },
          {
            name: '老吴',
            id_number: '123456789124', // 证件号
            gender: 0, // 性别
            date_of_birth: '2020-10-01', // 出生日期
            id_type_id: '身份证', // 证件类别
            id_expiry_date: '2020-10-01', // 证件有效期
          },
        ]),
      },
    ];
    const dataList: any = ref([]);
    dataList.value = list;
    // import_invoice({
    //   nsfile_id: nsfile_id,
    //   org_id: current_org?.id,
    // })
    //   .then((res: any) => {
    //     dataList.value = res;
    //     dataList.value.forEach((item: any) => {
    //       item.is_create = false;
    //     })
    //   })

    const is_create_number = (obj: any) => {
      obj.is_create = true;
    };
    const create_number: any = computed(() => {
      let num = 0;
      dataList.value.forEach((item: any) => {
        if (item.is_create) {
          num = num + 1;
        }
      });
      return num;
    });
    return {
      dataList,
      create_number,
      tableHeight,
      nsfile_id,
      is_create_number,
      btn_update_adviser,
    };
  },
  components: {
    ShowInsurance,
    ExclamationCircleOutlined,
    CheckCircleOutlined,
  },
});
</script>

<style lang="less" scoped>
.title {
  margin-bottom: 16px;
  color: @text-color;
  font-weight: 500;
  font-size: 16px;
}
</style>
